
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import DataImport from "@/components/scope/data-import.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import { Table, TableRowsSelect } from "@/utils/decorator";
import t from "@common/src/i18n/t";

const parts = namespace("parts");
const base = namespace("base");
const partsCategory = namespace("parts-category");
const management = namespace("management");
@Component({
  components: { TableEnableFlag, DataImport, SwitchStatus }
})
@Table("loadListData")
@Table.DataImport({
  templateUrl: "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/template/speedbiz/运营中心零配件导入.xlsx",
  actionUrl: "/filecloud/importOpParts"
})
@TableRowsSelect({
  warningMsg: t("setting.select-spare-parts"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
@TableRowsSelect({
  warningMsg: t("v210831.please-select-the-synchronize-parts"),
  updateStatusFuncName: "updateAsync",
  tableRefKey: "table"
})
export default class PartsList extends Vue {
  @parts.Action getPartsList;
  @parts.Action updateStatus;
  @partsCategory.Action getCategoryList;
  @base.Action getDictionaryList;
  @parts.Action getPartsBrandList;
  @management.Action syncStatusApi;
  asyncStatus = false;
  searchForm = {
    partsName: "",
    partsNumber: "",
    brandCode: "",
    categoryCode: ""
  };
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  sortForm: {};
  partsList: any[] = [];
  get searchList() {
    return [
      {
        label: t("parts.name"),
        type: "input",
        value: "",
        prop: "partsName"
      },
      {
        label: t("parts.code"),
        type: "input",
        value: "",
        prop: "partsNumber"
      },
      {
        label: t("setting.brand"),
        type: "select",
        selectList: this.brandList,
        value: "",
        prop: "brandCode"
      },
      {
        label: t("parts.category"),
        type: "cascader",
        prop: "categoryCode",
        value: "",
        options: this.categoryList,
        props: {
          label: "categoryName",
          value: "categoryCode"
        }
      }
    ];
  }
  categoryList: any[] = [];
  brandList: any[] = [];
  created() {
    this.init();
  }
  async init() {
    this.loadListData();
    this.getBrandList();
    const data = await this.getCategoryList();
    this.categoryList = this.addLeafFlag(data.data);
  }
  getBrandList() {
    this.getPartsBrandList().then(data => {
      this.brandList =
        data.data.list &&
        data.data.list.map(item => ({
          label: item.brandName,
          value: item.brandCode
        }));
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getPartsList({
      status: "9",
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      partsName: this.searchForm.partsName
    }).then(data => {
      this.partsList = data.data.list;
      return data;
    });
  }
  addLeafFlag(list) {
    const f = function(list) {
      list.forEach(item => {
        if (!item.children || item.children.length < 1) {
          delete item.children;
        } else {
          f(item.children);
        }
      });
    };
    f(list);
    return list;
  }
  @TableRowsSelect.updateMethod
  confirmUpdateStatus(status, selectedRow) {
    this.updateStatus({
      status: status,
      partsCode: selectedRow ? [selectedRow.partsCode] : this.mix_selectedRows.map(item => item.partsCode)
    }).then(data => {
      this.$message.success(status ? t("setting.enable-successful") : t("setting.disable-success"));
      this.loadListData();
    });
  }
  @TableRowsSelect.updateMethod
  updateAsync(status, selectedRow) {
    console.log("[ selectedRow ]", selectedRow);
    this.syncStatusApi({
      partsCode: this.row ? [this.row.partsCode] : this.mix_selectedRows.map(item => item.partsCode)
    }).then(data => {
      this.$message.success(t("v210831.synchronization-succeeded"));
      this.asyncStatus = false;
      this.loadListData();
    });
  }
  handleEdit(row) {
    this.$router.push(`/management/parts-edit/${row.partsCode}`);
  }
  handleView(row) {
    this.$router.push(`/management/parts-edit/${row.partsCode}?isView=1`);
  }
  row = undefined;
  handleSync(row) {
    console.log("[ row ]", row);
    console.log("[ row.partsCode ]", row.partsCode);
    if (row.partsCode) {
      this.row = row;
      this.asyncStatus = true;
    } else {
      if (this.mix_selectedRows.length < 1) {
        this.$message.error(t("setting.select-spare-parts"));
      } else {
        this.asyncStatus = true;
      }
    }
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.prop === "categoryCode" ? item.value[item.value.length - 1] : item.value;
      }
    });
    console.log(this.searchForm);
    this.loadListData();
  }
}
