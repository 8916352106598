import { render, staticRenderFns } from "./parts-list.vue?vue&type=template&id=74a57fd0&scoped=true"
import script from "./parts-list.vue?vue&type=script&lang=ts"
export * from "./parts-list.vue?vue&type=script&lang=ts"
import style0 from "./parts-list.vue?vue&type=style&index=0&id=74a57fd0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a57fd0",
  null
  
)

export default component.exports